import React from "react";
import "./Footer.css";
import Logo1 from "../../assets/images/logo1.png";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footerMainContainer">
      <div className="footerContainerA">
        <div className=" footerContainerA1">
          <img className="footerContainerA1Img" src={Logo1} alt="" />
        </div>
        <div className="tabletFooter">
          <div className=" footerContainerA2">
            <p className="footerHeading">Quick Links</p>
            <div className="footerContainerA2OptionContainer">
              <div className="footerPlusStyling"></div>
              <p
                className="footerText"
                onClick={() => {
                  navigate("/about");
                }}
              >
                About
              </p>
            </div>
            <div className="footerContainerA2OptionContainer">
              <div className="footerPlusStyling"></div>
              <p
                className="footerText"
                onClick={() => {
                  navigate("/services");
                }}
              >
                Services
              </p>
            </div>
            <div className="footerContainerA2OptionContainer">
              <div className="footerPlusStyling"></div>
              <p
                className="footerText"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact
              </p>
            </div>
          </div>
          <div className=" footerContainerA3">
            <p className="footerHeading">Contact</p>
            <p className="footerContainerA3Text1">+1 (346) 362-4459</p>
            <p className="footerContainerA3Text1">
              1321 Upland Dr. PMB 6334, Houston, Texas, 77043, US
            </p>
            <p className="footerContainerA3Text2">info@corporateprism.com</p>
          </div>
        </div>
        <div className=" footerContainerA4">
          <p className="footerHeading">Subscribe</p>
          <div className="footerDivider"></div>
          <p className="footerContainerA4Text2">
            Our expertise, as well as our passion for web design, sets us apart
            from other agencies.
          </p>
          <div>
            <div className="footerContainerA4Img2Container">
              <div
                className="footerContainerA4Img2 instagram"
                onClick={() => {}}
              ></div>
              <div
                className="footerContainerA4Img2 twitter"
                onClick={() => {}}
              ></div>
              <div
                className="footerContainerA4Img2 linkedin"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/corporate-prism/",
                    "_blank"
                  );
                }}
              ></div>
              <div
                className="footerContainerA4Img2 facebook"
                onClick={() => {
                  window.open(
                    "https://web.facebook.com/profile.php?id=61553743463827 ",
                    "_blank"
                  );
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerDivider2"></div>
      <div className="footerContainerB">
        <div className="footerContainerB1">
          <p className="footerContainerB1Text">
            Copyright © 2023 CORPORATE PRISM. All Rights Reserved
          </p>
        </div>
        <div className="footerContainerB2">
          <p className="footerContainerB2Text">Terms of Use</p>
          <p className="footerContainerB2Text">Privacy Policy</p>
        </div>
      </div>
    </div>
  );
}
