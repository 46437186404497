import React, { useState } from "react";
import "./Navbar.css";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import Logo1 from "../../assets/images/logo1.png";

export default function Navbar(props) {
  const navigate = useNavigate();
  const options = ["Home", "Services", "About", "Contact"];
  // const options = ["Home", "Services", "About", "Contact", "Blogs"];
  const [activeOption, setActiveOption] = useState(props.active);

  const handleOptionClick = (option) => {
    navigate(`/${option.toLowerCase()}`);
  };
  return (
    <>
      <div className="navbarMainContainer">
        <div className="navbarContainer1">
          <img
            className="navbarContainer1Img"
            src={Logo1}
            alt="logo"
            srcset=""
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="navbarContainer2">
          {options.map((item) => (
            <div
              key={item}
              className={`navbarOption ${
                item === activeOption ? "active" : "navbarOption"
              }`}
              onClick={() => handleOptionClick(item)}
            >
              <p>{item}</p>
            </div>
          ))}
        </div>
        <div className="navbarContainer3">
          <Sidebar />
        </div>
      </div>
    </>
  );
}
