import { Carousel } from "antd";
import "./CardCarousel.css";
// 1. Software Development
// 2. Web & Mobile Apps
// 3. E-commerce Solutions
// 4. Digital Marketing
// 5. Cloud Services
// 6. Artificial Intelligence
// 7. AR & VR
// 8. Data Management
// 9. IT Audit & Consultation
// 10. Cyber Security
import img1 from "../../assets/images/services/sd.png";
import img2 from "../../assets/images/services/web.png";
import img3 from "../../assets/images/services/ecom.png";
import img4 from "../../assets/images/services/dmar.png";
import img5 from "../../assets/images/services/cser.png";
import img6 from "../../assets/images/services/ai.png";
import img7 from "../../assets/images/services/ar.png";
import img8 from "../../assets/images/services/dman.png";
import img9 from "../../assets/images/services/it.png";
import img10 from "../../assets/images/services/csec.png";
import { useEffect, useState } from "react";

const MyCarousel = () => {
  const data = [
    {
      src: img1,
      number: "01",
      text: "Software Development",
    },
    {
      src: img2,
      number: "02",
      text: "Web & Mobile Apps",
    },
    {
      src: img3,
      number: "03",
      text: "E-commerce Solutions",
    },
    {
      src: img4,
      number: "04",
      text: "Digital Marketing",
    },
    {
      src: img5,
      number: "05",
      text: "Cloud Services",
    },
    {
      src: img6,
      number: "06",
      text: "Artificial Intelligence",
    },
    {
      src: img7,
      number: "07",
      text: "AR & VR",
    },
    {
      src: img8,
      number: "08",
      text: "Data Management",
    },
    {
      src: img9,
      number: "09",
      text: "IT Audit & Consultation",
    },
    {
      src: img10,
      number: "10",
      text: "Cyber Security",
    },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 767;
  const isTablet = windowWidth > 767 && windowWidth <= 1200;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="carouselContainer">
      {isMobile && (
        <Carousel slidesToShow={1} infinite autoplay autoplaySpeed={3000}>
          {data.map((item, index) => {
            return (
              <div className="carouselCard" key={index}>
                <p className="carouselNumber">{item.number}</p>
                <p className="carouselText">{item.text}</p>
                <img
                  alt={`related to ${item.text}`}
                  className="carouselImgStyling"
                  src={item.src}
                />
              </div>
            );
          })}
        </Carousel>
      )}
      {!isMobile && !isTablet && (
        <Carousel slidesToShow={5} infinite autoplay autoplaySpeed={3000}>
          {data.map((item, index) => {
            return (
              <div className="carouselCard" key={index}>
                <p className="carouselNumber">{item.number}</p>
                <p className="carouselText">{item.text}</p>
                <img
                  alt={`related to ${item.text}`}
                  className="carouselImgStyling"
                  src={item.src}
                />
              </div>
            );
          })}
        </Carousel>
      )}
      {isTablet && (
        <Carousel slidesToShow={3} infinite autoplay autoplaySpeed={3000}>
          {data.map((item, index) => {
            return (
              <div className="carouselCard" key={index}>
                <p className="carouselNumber">{item.number}</p>
                <p className="carouselText">{item.text}</p>
                <img
                  alt={`related to ${item.text}`}
                  className="carouselImgStyling"
                  src={item.src}
                />
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default MyCarousel;
