import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import DOMPurify from "dompurify";
import "./BlogPost.css";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import Footer from "../Footer/Footer";

export default function BlogPost() {
  const sanitizeHTML = (htmlContent) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlContent);
    return { __html: sanitizedHTML };
  };
  const [blogData, setBlogData] = useState({});
  const { title } = useParams();
  const q = query(collection(db, "blogPost"), where("title", "==", title));
  const getData = async () => {
    try {
      const querySnapshot = await getDocs(q);
      setBlogData(querySnapshot.docs[0].data());
      return;
    } catch (e) {
      console.log(e);
    }
  };
  getData();

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <div className="blogBox">
        <img
          style={{
            width: "80vw",
            height: 600,
          }}
          src={blogData.thumbnail}
        />
        <p style={{ color: "white" }}>{blogData.category}</p>
        <p style={{ color: "white" }}>{blogData.title}</p>
        <p
          style={{ color: "white" }}
          dangerouslySetInnerHTML={sanitizeHTML(blogData.content)}
        ></p>
      </div>
      <Footer />
    </>
  );
}
