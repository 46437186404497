import React from "react";
import './Vision.css'
import Tab from "../Tab/Tab";
import cpImg from "../../assets/images/homePic.jpg";


export default function Vision() {
  return (
    <div className="visionMainContainer">
      <div className="container1">
        <img className="visionImg" src={cpImg} alt="" />
      </div>
      <div className="container2">
        <p className="para1">WHO WE ARE</p>
        <p className="para2">Advancing Digital Excellence for Modern Businesses</p>
        <Tab />
      </div>
    </div>
  );
}
