import React, { useState } from "react";
import { Button, Drawer } from "antd";
import "./Sidebar.css";
import logo1 from "../../assets/images/logo1.png";
const Sidebar = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className="menuBtn" alt="" onClick={showDrawer}></Button>
      <Drawer placement="right" onClose={onClose} open={open}>
        <img alt="logo" className="sidebarImgStyling" src={logo1} />
        <p className="sidebarText1">
          Cultivating Business Excellence in the Digital Landscape.
        </p>
        <p className="sidebarText2">CONTACT</p>
        <p className="sidebarText3">Have any questions? Drop us an email:</p>
        <p className="sidebarText4">info@corporateprism.com </p>
        <p className="sidebarText2">SUBSCRIBE</p>

        <div className="sidebarDivider"></div>
        <div className="sidebarLinks">
          <div className="sidebarLink sidebarLink1"></div>
          <div className="sidebarLink sidebarLink2"></div>
          <div className="sidebarLink sidebarLink3"></div>
          <div className="sidebarLink sidebarLink4"></div>
        </div>
      </Drawer>
    </>
  );
};
export default Sidebar;
