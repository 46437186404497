import React, { useState, useEffect } from "react";
import "./BlogsPage.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import Preloader from "../Preloader/Preloader";
import Blog from "../Blog/Blog";
import Slider from "../Slider/Slider";

export default function BlogsPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeLoading();
  }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <Navbar active="Blogs" />
          <MobileNavbar active="Blogs" />
          <Blog />

          <Slider />

          <Footer />
        </div>
      )}
    </>
  );
}
