import React, { useEffect, useState } from "react";
import "./ServicesPage.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import MovingHeadline from "../MovingHeadline/MovingHeadline";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import Preloader from "../Preloader/Preloader";

export default function ServicesPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeLoading();
  }, []);

  const data = [
    {
      number: "01",
      title: "Software Development",
      text: `Unleash business potential with Corporate Prism's cutting-edge Software Development. From dynamic ERP solutions, including SAP and Microsoft Dynamics, to sophisticated CRM, HRM, and Inventory Management software, we redefine operational excellence. Elevate beyond boundaries with our bespoke solutions tailored to your distinct business needs.`,
    },
    {
      number: "02",
      title: "Web and Mobile App Development",
      text: `Transform your digital landscape with Corporate Prism's Mobile and Web Application prowess. From dynamic websites to e-commerce platforms and Android/iOS app development, and custom applications for businesses, we redefine online experiences.`,
    },
    {
      number: "03",
      title: "E-commerce Solutions",
      text: `Ignite your online business with Corporate Prism's Ecommerce expertse. From tailored platforms on Shopify, WordPress, and Magento to strategic marketplace expansion on Etsy and Amazon, we redefine online success. Choose us for Ecommerce solutions that stand out.`,
    },
    {
      number: "04",
      title: "Digital Marketing",
      text: `Supercharge your brand with Corporate Prism's Digital Marketing prowess. From Social Media dominance on Meta, Google, and TikTok to dynamic Content, Brand Management, and eye-catching Graphics, we redefine digital impact. Elevate visibility with expert PPC and SEO, crafting compelling Ads. At Corporate Prism, we engineer lasting digital experiences.`,
    },
    {
      number: "05",
      title: "Cloud Services",
      text: `Elevate your business with our cloud services at Corporate Prism. From seamless infrastructure setup and secure deployments to vigilant monitoring and disaster recovery, we ensure uninterrupted operations. Trust us for efficient cloud solutions that propel your business into the digital future.`,
    },
    {
      number: "06",
      title: "Artificial Intelligence",
      text: `At Corporate Prism, we lead in AI and ML innovation. From development and strategic consulting to crafting intelligent chatbots and integrating Generative AI, we propel your business into a future of enhanced efficiency and intelligence.`,
    },
    {
      number: "07",
      title: "AR & VR",
      text: `Revolutionize Reality with Corporate Prism's AR & VR. Elevate experiences, transcend boundaries. Immerse yourself in a new era of engagement where innovation meets limitless possibilities. Welcome to the forefront of digital evolution.`,
    },
    {
      number: "08",
      title: "Data Management",
      text: `Empower your business with Corporate Prism's Data Management. From preparation to advanced analytics, we redefine possibilities. Embrace the future with modernization, choosing precision and quality. Your data, reimagined.`,
    },
    {
      number: "09",
      title: "IT Audit & Consultation",
      text: `Level up your business with Corporate Prism's contemporary IT Audit and Consulting. We go beyond standards, offering GCR and GAP Analysis for ISO certifications, innovative risk assessment, advanced network optimization, and dynamic training programs. From documentation to end-to-end consultancy, we redefine success in the digital age.`,
    },
    {
      number: "10",
      title: "Cyber Security",
      text: `Corporate Prism excels in cybersecurity governance, compliance, managerial roles, policy-making, and risk assessment.`,
    },
  ];

  return (
    <div className="servicesPageMainContainer">
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <MobileNavbar active="Services" />
          <Navbar active="Services" />
          <div className="servicesHeaderContainer">
            <p className="servicesHeader">Services</p>
          </div>
          <div className="servicesContent">
            <div className="servicesContentSection1">
              <p className="servicesContentSection1Text1">OUR SERVICES</p>
              <div className="servicesTextContainer">
                <p className="servicesContentSection1Text2">INNOVATE </p>
                <span className="servicesContentSection1Text3a">EXECUTE</span>
                <p className="servicesContentSection1Text2 servicesContentSection1Text2Mobile">
                  ELEVATE
                  <span className="servicesContentSection1Text3b">.</span>
                </p>
              </div>
            </div>
            <div className="templatesMainContainer">
              {data.map((item, index) => {
                if (index % 2 === 0 && index + 1 < data.length) {
                  return (
                    <div className="templateContainer" key={index}>
                      <div className="template">
                        <p className="templateHeader">{item.title}</p>
                        <div className="templateDivider"></div>
                        <p className="templateText">{item.text}</p>
                      </div>

                      <div className="template">
                        <p className="templateHeader">
                          {data[index + 1].title}
                        </p>
                        <div className="templateDivider"></div>
                        <p className="templateText">{data[index + 1].text}</p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <MovingHeadline para1="INNOVATE" para2="EXECUTE" para3="ELEVATE" />
          <Footer />
        </>
      )}
    </div>
  );
}
