import { collection, deleteDoc, doc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/FirebaseConfig";
import "./DashboardList.css";
import Spinner from "../Spinner/Spinner";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Carousel, Popconfirm } from "antd";
import { Link } from "react-router-dom";

export default function DashboardList() {
  const [numberOfSlides, setNumberOfSlides] = useState("");
  const [loading, setLoading] = useState("");
  const data = [];
  const [dashboardList, setDashboardList] = useState([]);
  const getData = async () => {
    const q = query(collection(db, "blogPost"));
    setLoading(true);
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const blogData = {
          id: doc.id,
          ...doc.data(),
        };
        data.push(blogData);
      });
      setDashboardList(data);
      console.log(data);

      if (data.length < 2) {
        setNumberOfSlides(1);
      } else {
        setNumberOfSlides(2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteBlog = async (id) => {
    await deleteDoc(doc(db, "blogPost", id));
    getData();
  };

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Carousel slidesToShow={numberOfSlides} infinite>
          {dashboardList.map((item) => {
            return (
              <div className="carouselCard" key={item.id}>
                <Link to={`/dashboard/blog/${item.title}`}>
                  <p className="dashBoardListText">{item.title}</p>
                  <p className="dashBoardListText">{item.category}</p>
                  <img
                    alt={`related to ${item.title}`}
                    className="carouselImgStyling"
                    src={item.thumbnail}
                  />
                </Link>
                <Popconfirm
                  onConfirm={() => {
                    deleteBlog(item.id);
                  }}
                  title="Delete the task"
                  description="Are you sure to delete this task?"
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined
                    style={{
                      color: "#e66304",
                      fontSize: "1.25vw",
                      marginTop: 20,
                    }}
                  />
                </Popconfirm>

                <Link to={`/dashboard/editBlog/${item.id}`}>
                  <EditOutlined
                    style={{
                      color: "#e66304",
                      fontSize: "1.25vw",
                      marginTop: 20,
                      marginLeft: 20,
                    }}
                  />
                </Link>
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}
