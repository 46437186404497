import React from "react";
import "./Header.css";
import Stretch from "../Button/Stretch";
import arrow from "../../assets/images/arrow.png";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <div className="section2">
        <div className="section2a">
          <p className="section2aPara">INNOVATE</p>
        </div>
        <div className="section2b1">
          <p className="section2bPara1">EXECUTE</p>
        </div>
        <div className="section2b2">
          <p className="section2bPara2">ELEVATE</p>
          <img className="section2bImgStyling" src={arrow} alt="" />
        </div>
        <div className="section2c">
          <div className="section2c1">
            <p className="section2c1Para">
              Fostering Business Excellence within the Digital Landscape.
            </p>
          </div>
          <div className="section2c2">
            <Stretch
              text="GET IN TOUCH"
              buttonClick={() => {
                navigate("/contact");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
