import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../../firebase/FirebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Input } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import Stretch from "../Button/Stretch";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Spinner from "../Spinner/Spinner";

export default function EditBlog() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState({
    title: "",
    category: "",
    content: "",
  });
  const [thumbnail, setThumbnail] = useState("");

  const { id } = useParams();
  const docRef = doc(db, "blogPost", id);

  const getData = async () => {
    try {
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setBlogData({
          title: data.title,
          category: data.category,
          content: data.content,
          thumbnail: data.thumbnail,
        });
        setThumbnail(data.thumbnail);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const uploadImage = async (thumbnail) => {
    const imageRef = ref(storage, `blogimage/${thumbnail.name}`);
    try {
      const snapshot = await uploadBytes(imageRef, thumbnail);
      const imageUrl = await getDownloadURL(snapshot.ref);
      return imageUrl;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
    console.log(blogData);
  }, []);

  const updatePost = async () => {
    const docRef = doc(db, "blogPost", id);
    setLoading(true);
    try {
      let imageUrl = null;

      if (thumbnail && typeof thumbnail !== "string") {
        imageUrl = await uploadImage(thumbnail);
      } else {
        imageUrl = blogData.thumbnail;
      }
      await updateDoc(docRef, {
        title: blogData.title,
        category: blogData.category,
        content: blogData.content,
        thumbnail: imageUrl,
      });

      // console.log("data updated successfully");
      navigate("/corporaAdmin/dashboard");
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <div>
      <div className="createBlogContainer">
        <Input
          className="createBlogInput"
          placeholder="Enter Title"
          value={blogData.title}
          onChange={(e) => setBlogData({ ...blogData, title: e.target.value })}
        />
        <Input
          className="createBlogInput"
          placeholder="Enter Category"
          value={blogData.category}
          onChange={(e) =>
            setBlogData({ ...blogData, category: e.target.value })
          }
        />
        <Input
          className="createBlogInput"
          type="file"
          placeholder="Upload Thumbnail"
          onChange={(e) => {
            setThumbnail(e.target.files[0]);
          }}
        />
        {loading && <Spinner />}
        <Stretch text="Update" buttonClick={updatePost} />
        <h1>Blog Editor</h1>
        <Editor
          apiKey="52azpxlint6578ig6y02jzwojizl2xs98dnq2xalxjykm0c8"
          init={{
            plugins:
              "ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
            tinycomments_mode: "embedded",
            tinycomments_author: "Author name",
            mergetags_list: [
              { value: "First.Name", title: "First Name" },
              { value: "Email", title: "Email" },
            ],
            ai_request: (request, respondWith) =>
              respondWith.string(() =>
                Promise.reject("See docs to implement AI Assistant")
              ),
          }}
          initialValue={blogData.content}
          onEditorChange={(content) => {
            setBlogData({ ...blogData, content: content });
          }}
        />
      </div>
    </div>
  );
}
