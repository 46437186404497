import React from "react";
import "./Stretch.css";

export default function Stretch(props) {
  return (
    <button className="getInTouchButton" onClick={props.buttonClick}>
      {props.text}
    </button>
  );
}
