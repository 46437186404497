import React, { useState } from "react";
import { Button, Drawer } from "antd";
import "./MobileNavbar.css";
import cross from "../../assets/images/cross.png";
import { useNavigate } from "react-router-dom";

const MobileNavbar = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const options = ["Home", "Services", "About", "Contact"];
  // const options = ["Home", "Services", "About", "Contact", "Blogs"];
  const [activeOption, setActiveOption] = useState(props.active);
  const handleOptionClick = (option) => {
    navigate(`/${option.toLowerCase()}`);
  };

  return (
    <>
      <div className="mobileNavbar">
        <Button onClick={showDrawer} className="mobileMenuImg"></Button>
        <p
          className="mobileNavbarHeader"
          onClick={() => {
            navigate("/");
          }}
        >
          CORPORATE PRISM
        </p>
      </div>

      <Drawer placement="left" onClose={onClose} open={open}>
        <img
          style={{ color: "white", position: "absolute", right: 20, top: 20 }}
          src={cross}
          onClick={onClose}
          alt=""
        />
        <p
          className="mobileDrawerHeader"
          onClick={() => {
            navigate("/");
          }}
        >
          CORPORATE PRISM
        </p>
        <div className="mobileDrawerLinksContainer">
          {options.map((item) => (
            <div
              key={item}
              className={`navbarOption ${
                item === activeOption ? "active" : "navbarOption"
              }`}
              onClick={() => handleOptionClick(item)}
            >
              <div className="dot"></div>
              <p>{item}</p>
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};
export default MobileNavbar;
