import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import ServicesPage from "./components/ServicesPage/ServicesPage";
import Contact from "./components/Contact/Contact";
import BlogsPage from "./components/BlogsPage/BlogsPage";
import LoginForm from "./components/LoginForm/LoginForm";
import Dashboard from "./components/Dashboard/Dashboard";
import CreateBlog from "./components/CreateBlog/CreateBlog";
import BlogPost from "./components/BlogPost/BlogPost";
import EditBlog from "./components/EditBlog/EditBlog";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (!(user && user.uid === "pDVVlMoLihMaD6Exy7nRfSMfkmA2")) {
      navigate("/corporaAdmin");
    }
  }, [user, navigate]);

  return user && user.uid === "pDVVlMoLihMaD6Exy7nRfSMfkmA2" ? children : null;
};

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/services",
    element: <ServicesPage />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/blogs",
    element: <BlogsPage />,
  },
  {
    path: "/corporaAdmin",
    element: <LoginForm />,
  },
  {
    path: "/corporaAdmin/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/addBlog",
    element: (
      <ProtectedRoute>
        <CreateBlog />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/blog/:title",
    element: <BlogPost />,
  },
  {
    path: "/dashboard/editBlog/:id",
    element: (
      <ProtectedRoute>
        <EditBlog />
      </ProtectedRoute>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
