import React from "react";
import "./BlogCard.css";

export default function BlogCard(props) {
  return (
    <div className="blogCardMainContainer">
      <div className="blogCardContainer">
        <div className="blogCardSection1">
          <img className="blogCardImg" src={props.img} alt="" />
        </div>
        <div className="blogCardSection2">
          <p className="blogCardSection2Date">{props.date}</p>
        </div>
        <div className="blogCardSection3">
          <p className="blogCardSection3Title">{props.title}</p>
          <p
            className="blogCardSection3Text"
            dangerouslySetInnerHTML={props.text}
          ></p>
        </div>
        <div className="blogCardSection4">
          <div className="blogCardPlusImage"></div>
          <p className="blogCardSection3Btn">READ MORE</p>
        </div>
      </div>
    </div>
  );
}
