import React from "react";
import "./Services.css";
import Stretch from "../Button/Stretch";
import MovingHeadline from "../MovingHeadline/MovingHeadline";
import { useNavigate } from "react-router-dom";
import CardCarousel from "../CardCarousel/CardCarousel";

export default function Services() {
  const navigate = useNavigate();
  return (
    <div className="servicesContainer">
      <div className="container">
        <div className="textContainer">
          <p className="heading">Services</p>
        </div>
        <div className="btnContainer">
          <Stretch
            text="VIEW ALL SERVICES"
            buttonClick={() => {
              navigate("/services");
            }}
          />
        </div>
      </div>
      <CardCarousel />
      <MovingHeadline para1="INNOVATE" para2="EXECUTE" para3="ELEVATE" />
    </div>
  );
}
