import React from "react";
import "./MovingHeadline.css";
import arrow from "../../assets/images/arrow.png";

const MovingHeadline = (props) => {
  return (
    <div className="movingHeadline">
      <div className="headlineContainer">
        <p className="movingHeadlineSection2bPara">{props.para1}</p>
        <img
          className="movingHeadlineSection2bImgStyling"
          src={arrow}
          alt="arrow"
        />
        <p className="movingHeadlineSection2aPara">{props.para2}</p>
        <img
          className="movingHeadlineSection2bImgStyling"
          src={arrow}
          alt="arrow"
        />
        <p className="movingHeadlineSection2bPara">{props.para3}</p>
      </div>
    </div>
  );
};

export default MovingHeadline;
