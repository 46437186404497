import React, { useEffect, useState } from "react";
import "./Slider.css";
import { Carousel } from "antd";
import BlogCard from "../BlogCard/BlogCard";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase/FirebaseConfig";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

const Slider = () => {
  const [data1, setData1] = useState([]);
  const data = [];
  const getData = async () => {
    const q = query(collection(db, "blogPost"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const blogData = {
        id: doc.id,
        ...doc.data(),
      };
      data.push(blogData);
    });
    setData1(data);
    console.log(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const sanitizeHTML = (htmlContent) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlContent);
    return { __html: sanitizedHTML };
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 767;
  const isTablet = windowWidth > 767 && windowWidth <= 1024;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {isMobile && (
        <Carousel
          slidesToShow={1}
          infinite
          autoplay
          autoplaySpeed={3000}
          className="customCarousel"
        >
          {data1.map((item) => {
            return (
              <Link to={`/dashboard/blog/${item.title}`}>
                <BlogCard
                  key={item.id}
                  img={item.thumbnail}
                  date={item.date}
                  category={item.category}
                  title={item.title}
                  text={sanitizeHTML(item.content)}
                />
              </Link>
            );
          })}
        </Carousel>
      )}
      {!isMobile && !isTablet && (
        <Carousel
          slidesToShow={3}
          infinite
          autoplay
          autoplaySpeed={3000}
          className="customCarousel"
        >
          {data1.map((item) => {
            return (
              <Link to={`/dashboard/blog/${item.title}`}>
                <BlogCard
                  key={item.id}
                  img={item.thumbnail}
                  date={item.date}
                  category={item.category}
                  title={item.title}
                  text={sanitizeHTML(item.content)}
                />
              </Link>
            );
          })}
        </Carousel>
      )}
      {isTablet && (
        <Carousel
          slidesToShow={2}
          infinite
          autoplay
          autoplaySpeed={3000}
          className="customCarousel"
        >
          {data1.map((item) => {
            return (
              <Link to={`/dashboard/blog/${item.title}`}>
                <BlogCard
                  key={item.id}
                  img={item.thumbnail}
                  date={item.date}
                  category={item.category}
                  title={item.title}
                  text={sanitizeHTML(item.content)}
                />
              </Link>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default Slider;
