import React from "react";
import "./Availability.css";
import { useNavigate } from "react-router-dom";

export default function Availability() {
  const navigate = useNavigate();
  return (
    <div className="availablityMainContainer">
      <div className="availablityContainer">
        <span className="availabilityText1">
          Want to check availability or get in touch?
        </span>
        <span className="availabilityText2" onClick={()=>{navigate('/contact')}}>Contact us!</span>
      </div>
    </div>
  );
}
