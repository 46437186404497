import React from 'react'
import './style.css'

export default function Preloader() {
  return (
    <div id="preloader-wrapper">
      <div className="preloader-container">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
