import React from 'react';
import { Alert, Space } from 'antd';
const CustomAlert = (props) => (
    <Space
        direction="vertical"
        style={{
            width: '100%',
        }}
    >
        <Alert closable
            message={props.message}
            description={props.description}
            type={props.type}
            showIcon
        />
    </Space>
);
export default CustomAlert;