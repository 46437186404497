import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyASdc4bSAaVx2FAL23QZPS6_D4EQBa2-Dw",
  authDomain: "corporate-prism.firebaseapp.com",
  projectId: "corporate-prism",
  storageBucket: "corporate-prism.appspot.com",
  messagingSenderId: "1034758317853",
  appId: "1:1034758317853:web:4e8dff7eddc427f5ada3f4",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
