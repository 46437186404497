import React from "react";
import "./Blog.css";

export default function Blog() {
  return (
    <div className="blogMainContainer">
      <div className="blogSection1">
        <div className="blogSection1a">
          <p className="blogSection1aText1">INSIGHTS AND MOTIVATION</p>
          <p className="blogSection1aText2">THE CODED BLOG</p>
        </div>
        <div className="blogSection1b"></div>
      </div>
    </div>
  );
}
