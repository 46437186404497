import { Timestamp, addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useState } from "react";
import { db, storage } from "../../firebase/FirebaseConfig";
import Stretch from "../Button/Stretch";
import "./CreateBlog.css";
import { Input } from "antd";
import Spinner from "../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

export default function CreateBlog() {
  const [errorMessages, setErrorMessages] = useState({
    title: "",
    category: "",
    thumbnail: "",
    content: "",
  });
  const [blogs, setBlogs] = useState({
    title: "",
    category: "",
    content: "",
  });
  const [thumbnail, setThumbnail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const addPost = async () => {
    const errors = {
      title: "",
      category: "",
      content: "",
      thumbnail: "",
    };
    if (blogs.title.trim() === "") {
      errors.title = "Title is required.";
    }
    if (blogs.category.trim() === "") {
      errors.category = "Category is required.";
    }
    if (blogs.content.trim() === "") {
      errors.content = "Content is required.";
    }
    if (!thumbnail) {
      errors.thumbnail = "Thumbnail is required.";
    }
    setErrorMessages(errors);
    if (Object.values(errors).every((error) => error === "")) {
      setLoading(true);
      try {
        await uploadImage();
        navigate("/corporaAdmin/dashboard");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const uploadImage = async () => {
    if (!thumbnail) return;
    const imageRef = ref(storage, `blogimage/${thumbnail.name}`);

    try {
      const snapshot = await uploadBytes(imageRef, thumbnail);
      const url = await getDownloadURL(snapshot.ref);

      const blogPostRef = collection(db, "blogPost");
      await addDoc(blogPostRef, {
        ...blogs,
        thumbnail: url,
        time: Timestamp.now(),
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      });

      console.log("Post Added Successfully");
      setBlogs({
        title: "",
        category: "",
        content: "",
      });
      setThumbnail("");
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <div className="createBlogContainer">
      <Input
        className="createBlogInput"
        placeholder="Enter Title"
        onChange={(e) => setBlogs({ ...blogs, title: e.target.value })}
        value={blogs.title}
      />
      <p className="errorMessage">{errorMessages.title}</p>
      <Input
        className="createBlogInput"
        placeholder="Enter Category"
        onChange={(e) => setBlogs({ ...blogs, category: e.target.value })}
        value={blogs.category}
      />
      <p className="errorMessage">{errorMessages.category}</p>
      <Input
        className="createBlogInput"
        type="file"
        placeholder="Upload Thumbnail"
        onChange={(e) => setThumbnail(e.target.files[0])}
      />
      <p className="errorMessage">{errorMessages.thumbnail}</p>
      <Stretch text="Add" buttonClick={addPost} />
      {loading && <Spinner />}
      <h1>Blog Editor</h1>
      <Editor
        apiKey="52azpxlint6578ig6y02jzwojizl2xs98dnq2xalxjykm0c8"
        init={{
          plugins:
            "ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
          toolbar:
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          mergetags_list: [
            { value: "First.Name", title: "First Name" },
            { value: "Email", title: "Email" },
          ],
          ai_request: (request, respondWith) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        initialValue="kch likho"
        onEditorChange={(content) => {
          setBlogs({ ...blogs, content: content });
        }}
      />
      <p className="errorMessage">{errorMessages.content}</p>
    </div>
  );
}
