import React, { useState, useEffect } from "react";
import "./Contact.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import Preloader from "../Preloader/Preloader";
import emailjs from "emailjs-com";
import Spinner from "../Spinner/Spinner";
import CustomAlert from "../CustomAlert/CustomAlert";

export default function Contact() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeLoading();
  }, []);

  const [textAreaText, setTextAreaText] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [preferenceOfContact, setPreferenceOfContact] = useState("email");
  const [formSubmitLoading, setFormSubmitLoading] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const maxCharacters = 180;
  const resetFormFields = () => {
    setFname("");
    setLname("");
    setEmail("");
    setContactNumber("");
    setCompanyName("");
    setDesignation("");
    setPreferenceOfContact("");
    setTextAreaText("");
  };
  const handleChange = (event) => {
    const newText = event.target.value;

    if (newText.length <= maxCharacters) {
      setTextAreaText(newText);
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const [errorMessages, setErrorMessages] = useState({
    fname: "",
    lname: "",
    email: "",
    contactNumber: "",
    companyName: "",
    designation: "",
    preferenceOfContact: "",
  });

  const validateForm = () => {
    const errors = {
      fname: "",
      lname: "",
      email: "",
      contactNumber: "",
      designation: "",
      preferenceOfContact: "",
    };

    if (fname.trim() === "") {
      errors.fname = "First Name is required.";
    }

    if (lname.trim() === "") {
      errors.lname = "Last Name is required.";
    }

    if (email.trim() === "") {
      errors.email = "Email is required.";
    } else if (!isValidEmail(email)) {
      errors.email = "Invalid email format.";
    }

    if (contactNumber.trim() === "") {
      errors.contactNumber = "Contact Number is required.";
    }
    if (companyName.trim() === "") {
      errors.companyName = "Company Name is required.";
    }
    if (designation.trim() === "") {
      errors.designation = "Designation is required.";
    }
    if (preferenceOfContact.trim() === "") {
      errors.preferenceOfContact = "Preference of Contact is required.";
    }

    setErrorMessages(errors);

    return Object.values(errors).every((error) => error === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (validateForm()) {
        setFormSubmitLoading(true);

        const templateParams = {
          to_name: "Corporate Prism",
          from_name: `${fname} ${lname}`,
          first_name: `${fname}`,
          last_name: `${lname}`,
          email: email,
          contact_number: contactNumber,
          company_name: companyName,
          designation: designation,
          preference_of_contact: preferenceOfContact,
          message: textAreaText,
        };

        const serviceID = "service_a1jc1oc";
        const templateID = "template_usm3lcd";
        const userID = "FHzNnbuIKG2dmxLRF";

        await emailjs.send(serviceID, templateID, templateParams, userID);
        setSuccessAlert(true);
        resetFormFields();
      } else {
        console.error("Invalid form data. Please check the fields.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setErrorAlert(true);
    } finally {
      setFormSubmitLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="contactBackgroundImg">
          <Navbar active="Contact" />
          <MobileNavbar active="Contact" />
          <div className="contactMainContainer">
            <div className="contactHeaderContainer">
              <p className="contactHeader">Contact</p>
            </div>
            <div className="contactContainer">
              <div className="servicesContentSection1">
                <div className="servicesTextContainer">
                  <p className="servicesContentSection1Text2"> INNOVATE</p>
                  <span className="servicesContentSection1Text3a">EXECUTE</span>
                  <p className="servicesContentSection1Text2 servicesContentSection1Text2Mobile">
                    ELEVATE
                    <span className="servicesContentSection1Text3b">.</span>
                  </p>
                </div>
              </div>

              <div className="contactContainer2">
                <div className="contactFormContainer">
                  <p className="contactFormText1">SEND A MESSAGE</p>
                  <p className="contactFormText2">
                    All communication is strictly confidential.
                  </p>
                  <div className="contactFormInputMainContainer">
                    <div className="contactFormInputContainer">
                      <input
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        className="contactFormInput1"
                        placeholder="First Name *"
                        autoFocus
                      />
                      <div className="contactFormDivider"></div>
                      <p className="errorMessage">{errorMessages.fname}</p>
                    </div>
                    <div className="contactFormInputContainer">
                      <input
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        className="contactFormInput1"
                        placeholder="Last Name *"
                        autoFocus
                      />
                      <div className="contactFormDivider"></div>
                      <p className="errorMessage">{errorMessages.lname}</p>
                    </div>
                  </div>
                  <div className="contactFormInputMainContainer">
                    <div className="contactFormInputContainer">
                      <input
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="contactFormInput1"
                        placeholder="Email *"
                        autoFocus
                      />

                      <div className="contactFormDivider"></div>
                      <p className="errorMessage">{errorMessages.email}</p>
                    </div>
                    <div className="contactFormInputContainer">
                      <input
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        className="contactFormInput1"
                        placeholder="Contact Number *"
                        autoFocus
                      />

                      <div className="contactFormDivider"></div>
                      <p className="errorMessage">
                        {errorMessages.contactNumber}
                      </p>
                    </div>
                  </div>
                  <div className="contactFormInputMainContainer">
                    <div className="contactFormInputContainer">
                      <input
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className="contactFormInput1"
                        placeholder="Company Name *"
                        autoFocus
                      />

                      <div className="contactFormDivider"></div>
                      <p className="errorMessage">
                        {errorMessages.companyName}
                      </p>
                    </div>
                    <div className="contactFormInputContainer">
                      <input
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        className="contactFormInput1"
                        placeholder="Designation *"
                        autoFocus
                      />

                      <div className="contactFormDivider"></div>
                      <p className="errorMessage">
                        {errorMessages.designation}
                      </p>
                    </div>
                  </div>
                  <div className="contactFormInputMainContainer">
                    <div className="contactFormInputContainer">
                      <label className="contactFormInput1">
                        Preference of Contact *
                        <select
                          className="contactFormInput1"
                          onChange={(e) =>
                            setPreferenceOfContact(e.target.value)
                          }
                        >
                          <option value="email">Email</option>
                          <option value="phone">Phone</option>
                        </select>
                      </label>

                      <div className="contactFormDivider"></div>
                      <p className="errorMessage">
                        {errorMessages.preferenceOfContact}
                      </p>
                    </div>
                  </div>
                  <textarea
                    autoFocus
                    className="contactFormTextArea"
                    placeholder="Enter your message..."
                    value={textAreaText}
                    onChange={handleChange}
                  ></textarea>
                  <p className="contactFormTextAreaCounting">
                    {textAreaText.trim().length}/{maxCharacters}
                  </p>
                  {successAlert && (
                    <CustomAlert
                      type="success"
                      messsage="Success"
                      description="Your response has been submitted successfully."
                    />
                  )}
                  {errorAlert && (
                    <CustomAlert
                      type="error"
                      message="Failure"
                      description="An error occured while submitting your response"
                    />
                  )}

                  <button className="contactFormButton" onClick={handleSubmit}>
                    Send Message
                  </button>
                  {formSubmitLoading && <Spinner />}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}
