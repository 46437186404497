import React, { useState, useEffect } from "react";
import "./Tab.css";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";

const Mission = (props) => {
  const navigate = useNavigate();
  return (
    <div className="missionMainContainer">
      <p className="missionHeader">{props.header}</p>
      <p className="missionPara">{props.para}</p>
      <div className="readMoreContainer">
        <div
          className="introductionImgContainer"
          onClick={() => {
            navigate("/about");
          }}
        ></div>
        <p
          className="readMorePara"
          onClick={() => {
            navigate("/about");
          }}
        >
          READ MORE
        </p>
      </div>
    </div>
  );
};

const items = [
  {
    key: "1",
    label: "Introduction",
    children: (
      <Mission
        header="Introduction"
        para="Corporate Prism, a beacon of excellence in digital solutions. With a commitment to innovation and strategic partnership, we are your dedicated partners, guiding you through the intricacies of the digital landscape, and steering your path toward enduring success. Welcome to a realm where innovation meets precision, impact, and growth."
      />
    ),
  },
  {
    key: "2",
    label: "Vision",
    children: (
      <Mission
        header="Our Vision"
        para="We're a top global systems integration company. We use our business expertise, commitment to excellent service, customer-focused approach, and strong partnerships to excel. Our dedication to ongoing innovation, supporting people, and providing significant value underscores our commitment to clients and stakeholders."
      />
    ),
  },
  {
    key: "3",
    label: "Mission",
    children: (
      <Mission
        header="Our Mission"
        para="We help companies go digital and provide advanced solutions that support their goals in both the business world and society. By doing this, we aim to create success together with all involved parties in a collaborative and effective manner."
      />
    ),
  },
];

const Tab = () => {
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentIndex = items.findIndex((item) => item.key === activeKey);
      const nextIndex = (currentIndex + 1) % items.length;
      setActiveKey(items[nextIndex].key);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [activeKey]);

  return (
    <Tabs
      activeKey={activeKey}
      items={items}
      onChange={(key) => setActiveKey(key)}
    />
  );
};

export default Tab;
