import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import Vision from "../Vision/Vision";
import Services from "../Services/Services";
import Availability from "../Availability/Availability";
import Footer from "../Footer/Footer";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import Preloader from "../Preloader/Preloader";
import "./Home.css";
import BackToTopButton from "../BackToTop/BackToTopButton";

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeLoading();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <BackToTopButton />
          <Navbar active="Home" />
          <MobileNavbar active="Home" />
          <Header />
          <Vision />
          <Services />
          <Availability />
          <Footer />
        </>
      )}
    </div>
  );
}
