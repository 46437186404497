import React, { useEffect, useState } from "react";
import "./About.css";
import Navbar from "../Navbar/Navbar";
import MovingHeadline from "../MovingHeadline/MovingHeadline";
import Footer from "../Footer/Footer";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import Preloader from "../Preloader/Preloader";

export default function About() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeLoading = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeLoading();
  }, []);

  const data1 = [
    {
      heading: "Pioneers in Digital Transformation",
      text: `Corporate Prism has been fueled by an unwavering ambition to spearhead change and cultivate innovation within the dynamic digital landscape. Our trailblazing ethos is more than just a business approach; it's a dedicated pledge to consistently push the limits of what can be achieved in the realm of digital enterprise.

      It has consistently set a higher standard in digital services, transitioning from a startup to a distinguished digital agency synonymous with expertise, innovation, and unparalleled quality. We've been privileged to collaborate with esteemed brands and companies, crafting digital strategies that propel them to unprecedented levels of success.`,
    },
    {
      heading: "Our Team",
      text: `Corporate Prism's Success Anchored
      At the core of each project and achievement stands our most invaluable asset: the Corporate Prism team. Comprising experts and leaders in diverse domains, our team is a dynamic alliance of software architects, web developers, digital strategists, graphic designers, and marketing specialists. Bound by a shared vision and fueled by the infinite potential of the digital realm, every team member is dedicated to exceeding expectations and delivering outstanding results.`,
    },
  ];
  const data2 = [
    {
      heading: "Creating Tailored Digital Solutions",
      text: `What distinguishes Corporate Prism is our steadfast dedication to delivering customized digital solutions. Recognizing the distinctiveness of each client, the uniqueness of every project, and the singular importance of each goal, we shape our services to be not only tailor-made but also in alignment with our clients' strategic objectives.`,
    },
    {
      heading: "A Pledge to Sustainable Success",
      text: `We acknowledge that digital transformation is an ongoing journey, not a one-time initiative. This philosophy has forged enduring partnerships with our clients, enabling them to navigate market shifts, embrace technological advancements, and respond to evolving customer preferences.`,
    },
    {
      heading: "Where Passion Meets Excellence",
      text: `At Corporate Prism, we transcend mere project execution; we sculpt digital masterpieces. Whether large or intricate, each project is an avenue to propel our clients' aspirations forward and affirm our standing for excellence.`,
    },
    {
      heading: "The Future of Corporate Prism",
      text: `Looking ahead, our mission remains resolute: to evolve continually, to innovate persistently, and to unfailingly deliver digital solutions that empower businesses to attain unparalleled success.`,
    },
  ];
  return (
    <div className="aboutMainContainer">
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <Navbar active="About" />
          <MobileNavbar active="About" />
          <div className="aboutHeaderContainer">
            <p className="aboutHeader">About</p>
          </div>
          <div className="aboutContentSection">
            {data1.map((item, index) => {
              return (
                <div className="aboutContentContainer" key={index}>
                  <p className="aboutContentHeading">{item.heading}</p>
                  <p className="aboutContentText">{item.text}</p>
                </div>
              );
            })}
          </div>
          <MovingHeadline para1="INNOVATE" para2="EXECUTE" para3="ELEVATE" />
          <div className="aboutContentSection">
            {data2.map((item, index) => {
              return (
                <div className="aboutContentContainer" key={index}>
                  <p className="aboutContentHeading">{item.heading}</p>
                  <p className="aboutContentText">{item.text}</p>
                </div>
              );
            })}
          </div>
          <div className="aboutDivider"></div>
          <div className="aboutContentSection">
            <div className="aboutContentContainer">
              <p className="aboutContentText">
                We invite you to become a part of our continuing story of
                innovation and excellence. Reach out to us today to discover how
                we can propel your business into the digital future.
              </p>
            </div>
          </div>

          <Footer />
        </>
      )}
    </div>
  );
}
