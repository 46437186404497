import React, { useEffect, useState } from 'react';
import './LoginForm.css'
import { auth } from '../../firebase/FirebaseConfig';
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import Stretch from '../Button/Stretch';
const LoginForm = () => {
    const [loggedIn, setLoggedIn] = useState('')
    useEffect(() => {
        if (localStorage.getItem('userDetails')) {
            setLoggedIn(true)
        }
        else {
            setLoggedIn(false)
        }
    }, []);
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
    const loginFirebase = async (auth, provider) => {
        try {
            const response = await signInWithPopup(auth, provider)
            console.log(response)
            localStorage.setItem('userDetails', JSON.stringify(response.user))
            alert('login successful')
            navigate('/corporaAdmin/dashboard')
        } catch (e) {
            console.log(e.message)
        }
    }

    const logoutFirebase = async (auth) => {
        try {
            await signOut(auth)
            alert('logout successful')
            localStorage.removeItem('userDetails')
            setLoggedIn(false)
        }
        catch (e) {
            console.log(e)
        }
    }
    return (<div className='loginFormMainContainer'>
        {!loggedIn ? <Stretch buttonClick={() => { loginFirebase(auth, provider) }} text="Sign In" /> : <Stretch buttonClick={() => { logoutFirebase(auth) }} text="Sign Out" />}


    </div>)
}
export default LoginForm;