import React from "react";
import "./Dashboard.css";
import DashboardList from "../DashboardList/DashboardList";
import Stretch from "../Button/Stretch";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  return (
    <div className="dashboardMainContainer">
      <p className="dashboardHeading">DASHBOARD</p>
      <Stretch
        text="Add Blog"
        buttonClick={() => {
          navigate("/dashboard/addBlog");
        }}
      />
      <DashboardList />
    </div>
  );
}
